import React, { useState } from "react";
import DesignProcessData from "../Asserts/DesignProcessData";
import "../Css/Services.css";
import THEARTHOUSEVILLA from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/ARTHOUSEONE.avif';
import imgone from '../Imagess/THE STUDIO/discovery and conceptulization.jpg'
import imgtwo from '../Imagess/THE STUDIO/design developement (1).png'
import imgthree from '../Imagess/THE STUDIO/technical design and documentation.jpeg'
import imgfour from '../Imagess/THE STUDIO/execution and project management.JPG'
import imgfive from '../Imagess/THE STUDIO/interior design and styling (1).jpg'
import imgsix from '../Imagess/THE STUDIO/Handover and Completion.jpg'
import imgseven from '../Imagess/THE STUDIO/post completion support (1).jpg'

import Accordion from "react-bootstrap/Accordion";
import { PiPlusThin } from "react-icons/pi";
// import MainBanner from "../Pages/MainBanner.jsx";
import banner from "../Images/Home/1 (9).webp";
import { MainBanner } from "./MainBanner";
import TitleSection from "./TitleSection";

const DesignProcess = () => {
  window.scrollTo(0, 0);
  const [activeIndex, setActiveIndex] = useState(null);

  return (
   <>
   {/* <section  style={{background:"rgb(211, 211, 211)"}}> */}
   <TitleSection title='Design Process' titlePara='"Step-by-Step to Stunning: Experience Our Design Process."
' />

   {/* </section> */}
    {/* <MainBanner title="Design Process" imgURL={banner} para="Spoin eravida gibh sel velit tuctor aliquet. Aenean dyfi iysda asslicikoi tudin lorem quis bibendum auctor" /> */}
     {/* <MainBanner title="Meet The Team" imgURL={banner} para="Spoin eravida gibh sel velit tuctor aliquet. Aenean dyfi iysda asslicikoi tudin lorem quis bibendum auctor" /> */}
    {/* <MainBanner /> */}
<section>
  <div className="my-5">
    {/* <Accordion> */}
    <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>01</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}> Discovery & Conceptualization</h5>
              <br/>
              The process begins with discovery—a deep dive into the client’s needs, lifestyle, and aspirations. We conduct detailed discussions to understand the project’s scope, requirements, site conditions, and budget. This phase sets the foundation for the design concept, where we explore ideas, inspirations, and possibilities through sketches, mood boards, and preliminary layouts. We focus on defining the project’s unique identity while ensuring alignment with functional goals.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=""
                  src={imgone}
                  alt='imagee'
                />
              </div>
            </div>
          </div>
          </div>




          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgtwo}
                  alt='imagee'
                />
              </div>
            </div>
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>02</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}> Design Development</h5>
              <br/>
              Once the initial concept is approved, we move into the design development phase. This is where the ideas take shape, translating abstract concepts into tangible designs. Floor plans, elevations, sections, and 3D visualizations are developed to communicate the spatial organization, material palette, and overall design direction. At this stage, we refine details, make adjustments, and ensure the design is both aesthetically appealing and technically feasible. Collaboration with the client is key, as we gather feedback and make refinements to meet expectations
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>


          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>03</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}>Technical Design & Documentation</h5>
              <br/>
              With the design finalized, we progress to creating detailed technical drawings and documentation. These drawings include structural details, MEP (Mechanical, Electrical, Plumbing) layouts, materials, and finishes— all necessary for accurate execution. In this phase, we collaborate with engineers, contractors, and consultants to ensure the design integrates seamlessly with the structural and technical aspects of the project. Every element is scrutinized to ensure precision, durability, and adherence to local building codes and regulations.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgthree}
                  alt='imagee'
                />
              </div>
            </div>
          </div>
          </div>




          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgfour}
                  alt='imagee'
                />
              </div>
            </div>
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>04</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}>Execution & Project Management</h5>
              <br/>
              With comprehensive documentation in place, the construction or implementation phase begins. Our project management team takes charge, coordinating with contractors, vendors, and artisans to ensure the design is executed to the highest standards. We provide on-site supervision, regularly monitor progress, and troubleshoot any issues that arise during construction. The focus is on maintaining design integrity, meeting timelines, and ensuring quality craftsmanship.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>


          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>05</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}> Interior Design & Styling</h5>
              <br/>
              For interior design projects, we move into the styling phase after construction. This involves selecting furniture, fixtures, lighting, and décor elements that align with the overall design vision. We curate pieces that enhance the functionality and aesthetic of the space, considering every detail from color schemes to textures and materials. This phase brings the project to life, ensuring the space is cohesive and reflects the client’s personality and preferences.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgfive}
                  alt='imagee'
                />
              </div>
            </div>
          </div>
          </div>




          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgsix}
                  alt='imagee'
                />
              </div>
            </div>
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>06</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}>Handover & Completion</h5>
              <br/>
              The final phase is the project handover, where we ensure every element has been executed according to plan. We conduct a final walkthrough with the client to confirm that all aspects meet expectations. Any minor adjustments or final touches are addressed before we officially hand over the completed project. Our goal is to deliver a space that not only meets but exceeds the client’s vision, ensuring a smooth and satisfying end to the process.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
          </div>
          </div>




          <div className="container mt-5">
          <div className="row">
            <div className="col-md-6 px-5 my-2" style={{display:'flex'}}>
              <div className="service-grid-left">
              {/* <h2 > {v.subTitle}</h2> */}
              
              <p className="service-para text-muted">
                <h2 className="display-5" style={{WebkitTextStroke:'1px black',color:'white'}}>07</h2><br/>
              <h5 className="" style={{fontWeight:'bold'}}> Post-Completion Support</h5>
              <br/>
              Even after project completion, we offer post-handover support. Whether it’s additional tweaks or guidance on maintenance, we remain available to ensure the long-term success and satisfaction of our designs.
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-6 px-2 pt-2 ">
              <div style={{display:"flex",justifyContent:"center"}}>
                <img
                style={{height:'450px',width:'450px'}}
                  className=" "
                  src={imgseven}
                  alt='imagee'
                />
              </div>
            </div>
          </div>
          </div>
      {/* {DesignProcessData.map((v,i)=>{
        return(
            <> */}
            {/* <Accordion.Item  eventKey={i}> */}
      {/* <hr /> */}
        {/* <Accordion.Header className="container mt-5"> */}
          {" "}
          
        {/* </Accordion.Header>
        <Accordion.Body className="container mt-5"> */}
       {/* <div className="container mt-5">
          <div className="row">
            <div className="col-md-6" style={{display:'flex'}}>
              <div className="service-grid-left"> */}
              {/* <h2 > {v.subTitle}</h2> */}
              
              {/* <p className="service-para text-muted">
              <h5 className="text-muted accordion-title"> {v.titleName}</h5>
                {v.servicePara}
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con"> */}
                  {/* <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div> */}
                {/* </div>
              </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img
                  className=" "
                  src={v.serviceImg}
                  alt='imagee'
                />
              </div>
            </div>
          </div>
          </div> */}
        {/* </Accordion.Body>
      </Accordion.Item> */}
            {/* </>
        )
      })} */}
   
      {/* <Accordion.Item  eventKey="1">
        <Accordion.Header className="container mt-5">
          {" "}
          <h2> Services</h2>
        </Accordion.Header>
        <Accordion.Body className="container mt-5">
       
          <div className="row">
            <div className="col-md-6">
              <div className="service-grid-left">
              <h2> Consulation</h2>
              <p className="service-para text-muted">
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                do eiusmod tempor incididunt ut labore et dolore magna
                aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                ullamco laboris nisi ut aliquip ex ea commodo consequat.
                Duis aute irure dolor in reprehenderit in voluptate velit
                esse cillum dolore eu fugiat nulla
              </p>
              <div className="service-section-page-plus-btn">
                <div className="service-section-svg-overlay-btn-con">
                  <div className="service-section-overlay-btn text-white">
                    <PiPlusThin />
                  </div>
                </div>
              </div>
              </div>
            </div>
            <div className="col-md-6">
              <div>
                <img
                  className=" "
                  src="https://dessau.qodeinteractive.com/wp-content/uploads/2018/05/h1-img-3.jpg"
                />
              </div>
            </div>
          </div>
        </Accordion.Body>
      </Accordion.Item> */}
    {/* </Accordion> */}
  </div>
</section>
   </>
  );
};

export default DesignProcess;
