// import ashokvatikaone from "./images/ashokvatikaone/ashokvatikaone.jpg";
// import one from "./images/ashokvatikaone/one.png";
// import two from "./images/ashokvatikaone/two.jpg";
// import three from "./images/ashokvatikaone/three.png";
// import four from "./images/ashokvatikaone/four.jpg";
// import balbalamarket from "./images/balbalamarket.jpg";
// import buddhabhumi from "./images/buddhabumi.jpeg";
// import CHINCHPOKLISTATION from "./images/CHINCHPOKLISTATION.jpg";
// import Dindoshi from "./images/Dindoshi.jpg";
// import Hearthstone from "./images/Hearthstone/Hearthstone.jpg";
// import hone from "./images/Hearthstone/hone.jpeg";
// import htwo from "./images/Hearthstone/htwo.jpeg";
// import hthree from "./images/Hearthstone/hthree.jpg";
// import hfour from "./images/Hearthstone/hfour.jpg";
// import Iskonecovillage from "./images/Iskonecovillage/Iskonecovillage.jpg";
// import ione from "./images/Iskonecovillage/ione.jpg";
// import itwo from "./images/Iskonecovillage/itwo.png";
// import ithree from "./images/Iskonecovillage/ithree.jpg";
// import ifour from "./images/Iskonecovillage/ifour.jpg";
// import JIJAMATAUDHYAN from "./images/JIJAMATAUDHYAN.jpg";
// import MASHRABIAVILLADJIBOUTI from "./images/MASHRABIAVILLADJIBOUTI/MASHRABIAVILLADJIBOUTI.jpg";
// import mone from "./images/MASHRABIAVILLADJIBOUTI/mone.jpg";
// import mtwo from "./images/MASHRABIAVILLADJIBOUTI/mtwo.jpg";
// import mthree from "./images/MASHRABIAVILLADJIBOUTI/mthree.jpg";
// import mfour from "./images/MASHRABIAVILLADJIBOUTI/mfour.jpg";

// import MLCPMATUNGA from "./images/MLCPMATUNGA.jpeg";
// import MLCPMUMBADEVI from "./images/MLCPMUMBADEVI.jpeg";
// import TRUPTI from "./images/TRUPTI.jpg";
// import TWINVILLASATARA from "./images/TWINVILLASATARA/TWINVILLASATARA.jpg";
// import sone from "./images/TWINVILLASATARA/sone.png";
// import stwo from "./images/TWINVILLASATARA/stwo.jpg";
// import sthree from "./images/TWINVILLASATARA/sthree.png";
// import sfour from "./images/TWINVILLASATARA/sfour.png";
// import VEERSAWARKARMARKET from "./images/VEERSAWARKARMARKET.jpg";
// import TWINVILLASDJIBOUTI from "./images/TWINVILLASDJIBOUTI/TWINVILLASDJIBOUTI.png";
// import done from "./images/TWINVILLASDJIBOUTI/done.png";
// import dtwo from "./images/TWINVILLASDJIBOUTI/dtwo.jpg";
// import dthree from "./images/TWINVILLASDJIBOUTI/dthree.jpg";
// import dfour from "./images/TWINVILLASDJIBOUTI/dfour.jpg";
// import bbmpoffice from "./images/bbmpoffice.jpg";
// import dindoshif from "./images/dindoshif.jpg";
// import Hindughymkhana from "./images/Hindughymkhana.jpg";
// import NasikOffice from "./images/NasikOffice.jpg";
// import VEERSAWARKARMARKETa from "./images/VEERSAWARKARMARKETa.jpg";
// import Apartmentsixzerotwo from "./images/602Apartment.jpg";
// import BISENVILLA from "./images/BISENVILLA.png";
// import GUNERESIDENCE from "./images/GUNERESIDENCE.jpeg";
// import HOFFICE from "./images/HOFFICE.JPG";
// import HEARTHSTONEARABELLA from "./images/HEARTHSTONEARABELLA.jpg";
// import HOUSEOFGREEN from "./images/HOUSEOFGREEN.jpg";
// import JAINRESIDENCE from "./images/JAINRESIDENCE.jpeg";
// import MASHRABIAVILLA from "./images/MASHRABIAVILLA.png";
// import NSMSCHOOLICSE from "./images/NSMSCHOOLICSE.jpg";
// import SUNLITSPLENDOR from "./images/SUNLITSPLENDOR.jpg";
// import THEARTHOUSEVILLA from "./images/THEARTHOUSEVILLA18.jpg";
// import THESTUDIO from "./images/THESTUDIO.jpg";
// import TRUPTII from "./images/TRUPTII.jpg";
// import ahokone from "./images/ashokvatikaone/one.png";
// import ahoktwo from "./images/ashokvatikaone/two.jpg";
// import ahokthree from "./images/ashokvatikaone/three.png";
// import ahokfoutr from "./images/ashokvatikaone/four.jpg";
// import ahokfive from "./images/ashokvatikaone/ashokvatikaone.jpg";
// import twinone from "./images/twinvillas/TWINONE.png";
// import twintwo from "./images/twinvillas/TWINTWO.png";
// import twinthree from "./images/twinvillas/TWINTHREE.jpg";
// // import b1 from "./Images/bankofindia/b1.jpeg"
// import b2 from "./images/bankofindia/b2.jpeg";
// import b3 from "./images/bankofindia/b3.jpeg";
// import b4 from "./images/bankofindia/b4.jpeg";
// import b5 from "./images/bankofindia/b5.jpeg";
// // import b6 from "./Images/bankofindia/b6.jpeg"
// import b7 from "./images/bankofindia/b7.jpeg";
// import b8 from "./images/bankofindia/b8.jpeg";
// import jija1 from "./images/jija/jija1.png";
// import jija2 from "./images/jija/jija2.png";
// import jija3 from "./images/jija/jija3.jpg";

// import hind2 from "./images/hindhugymkhana/hind2.jpg";

// import hind4 from "./images/hindhugymkhana/hind4.jpg";

// import hind6 from "./images/hindhugymkhana/hind6.jpg";

// import hind8 from "./images/hindhugymkhana/hind8.jpg";
// // import hind9 from "./Images/hindhugymkhana/hind9.jpg"
// import hind10 from "./images/hindhugymkhana/hind10.jpg";
// // import twinfour from "./Images/twinvillas/TWINFOUR.jpg"

// import nashik1 from "./images/NASHIK/NASHIK1.jpg";
// import nashik2 from "./images/NASHIK/NASHIK2.jpg";
// import nashik3 from "./images/NASHIK/NASHIK3.jpg";
// import nashik4 from "./images/NASHIK/NASHIK4.jpeg";
// import balaji1 from "./images/balajimarket/balaji1.jpg"
// import balaji2 from "./images/balajimarket/balaji2.jpg"
// import balaji3 from "./images/balajimarket/balaji3.jpg"
// import buddha1 from "./images/BUDDHA/BUDDHA1.png"

// import buddha2 from "./images/BUDDHA/BUDDHA2.png"
// import buddha3 from "./images/BUDDHA/BUDDHA3.png"
// import buddha4 from "./images/BUDDHA/BUDDHA4.png"
// import buddha5 from "./images/BUDDHA/BUDDHA5.jpg"
// import buddha6 from "./images/BUDDHA/BUDDHA6.jpg"
// import buddha7 from "./images/BUDDHA/BUDDHA7.jpg"
// import buddha8 from "./images/BUDDHA/BUDDHA8.jpg"
// import buddha9 from "./images/BUDDHA/BUDDHA9.jpg"
// import buddha10 from "./images/BUDDHA/BUDDHA10.jpg"
// import buddha11 from "./images/BUDDHA/BUDDHA11.jpg"
// import buddha12 from "./images/BUDDHA/BUDDHA12.jpg"
// import buddha13 from "./images/BUDDHA/BUDDHA13.png"
// import chin1 from "./images/chin/chin1.jpg"
// import chin2 from "./images/chin/chin2.jpg"
// import chin3 from "./images/chin/chin3.jpg"
// import chin4 from "./images/chin/chin4.jpg"
// import chin5 from "./images/chin/chin5.jpg"
// import iskon1 from "./images/iskon/iskon1.jpg"
// import iskon2 from "./images/iskon/iskon2.jpg"
// import din1 from "./images/din1.jpg"
// import din2 from "./images/din2.jpg"
// import din3 from "./images/din3.jpg"
// import mlcp1 from "./images/mlcp/mlcp1.png"
// import mlcp2 from "./images/mlcp/mlcp2.png"
// import mlcp3 from "./images/mlcp/mlcp3.jpeg"
// import mlcpmum1 from "./images/mclpmumbai/mlcpmumbai1.jpeg"
// import mlcpmum2 from "./images/mclpmumbai/mlcpmumbai2.jpeg"
// import mlcpmum3 from "./images/mclpmumbai/mclpmumbai3.jpeg"
// import basin1 from "./images/BASIN/BASIN1.png"
// import basin from "./images/BASIN/BASIN2.png"
// import basin3 from "./images/BASIN/BASIN3.png"
// import basin4 from "./images/BASIN/BASIN4.jpeg"
// import basin5 from "./images/BASIN/BASIN5.jpg"
// import basin6 from "./images/BASIN/BASIN6.jpg"
// import basin7 from "./images/BASIN/BASIN7.png"
// import basin8 from "./images/BASIN/BASIN8.png"
// import basin9 from "./images/BASIN/BASIN9.jpg"
// import basin10 from "./images/BASIN/BASIN10.jpg"
// import mahavir1 from "./images/mahavirvilla/maha1.png"
// import mahavir2 from "./images/mahavirvilla/maha2.png"
// import mahavir3 from "./images/mahavirvilla/maha3.png"
// import mahavir4 from "./images/mahavirvilla/maha4.png"
// import mahavir5 from "./images/mahavirvilla/maha5.png"
// import mahavir6 from "./images/mahavirvilla/maha6.png"
// import mahavir7 from "./images/mahavirvilla/maha7.png"
// import mahavir8 from "./images/mahavirvilla/maha8.png"
// import mahavir9 from "./images/mahavirvilla/maha9.png"
// import sunlit1 from "./images/SUNLIT/SUNLIT1.jpg"
// import  sunlit2 from "./images/SUNLIT/SUNLIT2.webp"
// import sunlit3 from "./images/SUNLIT/SUNLIT3.webp"
// import sunlit4 from "./images/SUNLIT/SUNLIT4.jpg"
// import sunlit5 from "./images/SUNLIT/SUNLIT5.webp"
// import sunlir6 from "./images/SUNLIT/SUNLIT6.webp"
// import sunlit7 from "./images/SUNLIT/SUNLIT7.jpg"
// import art1 from "./images/thearthouse/art1.jpg"
// import art2 from "./images/thearthouse/art2.jpg"
// import art3 from "./images/thearthouse/art3.jpg"
// import art4 from "./images/thearthouse/art4.jpg"
// import art5 from "./images/thearthouse/art7.jpg"
// import gune1 from "./images/gune/gune1.jpeg"

// import gune3 from "./images/gune/gune3.jpeg"
// import gune4 from "./images/gune/gune4.jpeg"
// import gune5 from "./images/gune/gune5.jpeg"
// import gune6 from "./images/gune/gune6.jpeg"
// import gune7 from "./images/gune/gune7.jpeg"
// import gune8 from "./images/gune/gune8.jpeg"
// import jain1 from "./images/jain/jain1.jpg"
// import jain2 from "./images/jain/jain2.jpg"
// import jain3 from "./images/jain/jain3.jpg"
// import jain4 from "./images/jain/jain4.jpg"
// import jain5 from "./images/jain/jain5.jpg"
// import jain6 from "./images/jain/jain6.jpg"
// import jain7 from "./images/jain/jain7.jpeg"
// import jain8 from "./images/jain/jain8.jpeg"







// Starting importing of new images with main image first
          // Architecture Section With All Sub Section
                      // Architecture Section , Sub Section :- Resedential
    
         // ASHOK VATIKA
         import ashokvatikaone from '../Imagess/Architecture/Residential/ASHOK VATIKA/ashokvatikaone.avif'
         import a from '../Imagess/Architecture/Residential/ASHOK VATIKA/one.avif'
         import b from '../Imagess/Architecture/Residential/ASHOK VATIKA/two.avif'
         import c from '../Imagess/Architecture/Residential/ASHOK VATIKA/three.avif'
         import d from '../Imagess/Architecture/Residential/ASHOK VATIKA/four.avif'
         import fiverr from '../Imagess/Architecture/Residential/ASHOK VATIKA/five.avif'
         import sixrr from '../Imagess/Architecture/Residential/ASHOK VATIKA/six.avif'
         import sevenrr from '../Imagess/Architecture/Residential/ASHOK VATIKA/seven.avif'
         import eightrr from '../Imagess/Architecture/Residential/ASHOK VATIKA/eight.avif'
         
         
         
         
         //Hearth stone
         import HEARTHSTONEONE from '../Imagess/Architecture/Residential/HEARTH STONE/HEARTHSTONEONE.avif'
         import e from '../Imagess/Architecture/Residential/HEARTH STONE/one.avif'
         import f from '../Imagess/Architecture/Residential/HEARTH STONE/two.avif'
         import g from '../Imagess/Architecture/Residential/HEARTH STONE/three.avif'
         import h from '../Imagess/Architecture/Residential/HEARTH STONE/four.avif'
         import fives from '../Imagess/Architecture/Residential/HEARTH STONE/five.avif'
         import sixs from '../Imagess/Architecture/Residential/HEARTH STONE/six.avif'
         import sevens from '../Imagess/Architecture/Residential/HEARTH STONE/seven.avif'
         import eights from '../Imagess/Architecture/Residential/HEARTH STONE/eight.avif'
         import nines from '../Imagess/Architecture/Residential/HEARTH STONE/nine.avif'
         import tens from '../Imagess/Architecture/Residential/HEARTH STONE/ten.avif'
         import elevens from '../Imagess/Architecture/Residential/HEARTH STONE/eleven.avif'
         import twelves from '../Imagess/Architecture/Residential/HEARTH STONE/twelve.avif'
         import thirteens from '../Imagess/Architecture/Residential/HEARTH STONE/thirteen.avif'
         
         //mashrabia
         import MASHRABIAONE from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/MASHRABIAVILLAONE .avif'
         import mone from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/one.avif'
         import mtwo from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/two.avif'
         import threet from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/three.avif'
         import fourt from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/four.avif'
         import mfive from '../Imagess/Architecture/Residential/MASHRABIA VILLA DJIBOUTI/five.avif'
         
         
         
         //twin villa
         import TWINONE from '../Imagess/Architecture/Residential/TWIN VILLA DJIBOUTI/TWINVILLASONE.avif'
         import i from '../Imagess/Architecture/Residential/TWIN VILLA DJIBOUTI/one.avif'
         import j from '../Imagess/Architecture/Residential/TWIN VILLA DJIBOUTI/two.avif'
         import k from '../Imagess/Architecture/Residential/TWIN VILLA DJIBOUTI/three.avif'
         import l from '../Imagess/Architecture/Residential/TWIN VILLA DJIBOUTI/four.avif'
         
         
         
                                // Architecture Section , Sub Section :- Cultural
                 //BUDDHA BHUMI
         import BUDDHABHUMI from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMI.avif'
         import BUDDHABHUMIimgone from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgone.avif'
         import BUDDHABHUMIimgtwo from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgtwo.avif'
         import BUDDHABHUMIimgthree from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgthree.avif'
         import BUDDHABHUMIimgfour from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgfour.avif'
         import BUDDHABHUMIimgfive from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgfive.avif'
         import BUDDHABHUMIimgsix from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgsix.avif'
         import BUDDHABHUMIimgseven from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgseven.avif'
         import BUDDHABHUMIimgeight from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgeight.avif'
         import BUDDHABHUMIimgnine from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgnine.avif'
         import BUDDHABHUMIimgten from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgten.avif'
         import BUDDHABHUMIimgeleven from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgeleven.avif'
         import BUDDHABHUMIimgtwelve from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgtwelve.avif'
         import BUDDHABHUMIimgthirteen from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgthirteen.avif'
         import BUDDHABHUMIimgfourteen from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgfourteen.avif'
         import BUDDHABHUMIimgfifteen from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgfifteen.avif'
         import BUDDHABHUMIimgsixteen from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgsixteen.avif'
         import BUDDHABHUMIimgsixteenn from '../Imagess/Architecture/Cultural/BUDDHA BHUMI/BUDDHABHUMIimgsixteenn.avif'
         
         
                 //ISKCON ECO VILLAGE
         import ISKCONECOVILLAGEONEA from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/ISKCONECOVILLAGEONE.avif'
         import q from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/one.avif'
         import r from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/two.avif'
         import s from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/three.avif'
         import t from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/four.avif'
         import fiveu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/five.avif'
         import sixu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/six.avif'
         import sevenu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/seven.avif'
         import eightu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/eight.avif'
         import nineu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/nine.avif'
         import tenu from '../Imagess/Architecture/Cultural/ISKCON ECO VILLAGE/ten.avif'
         
         
         
         //JIJAMATA UDHYAN
         import JIJAMATAUDHYANONEA from '../Imagess/Architecture/Cultural/JIJAMATA UDHYAN/JIJAMATAUDHYANONE.avif'
         import one from '../Imagess/Architecture/Cultural/JIJAMATA UDHYAN/one.avif'
         import two from '../Imagess/Architecture/Cultural/JIJAMATA UDHYAN/two.avif'
         import three from '../Imagess/Architecture/Cultural/JIJAMATA UDHYAN/three.gif'
         import four from '../Imagess/Architecture/Cultural/JIJAMATA UDHYAN/four.avif'
         
         
         
                               // Architecture Section , Sub Section :- Civivc
                   //BALBALA MARKET
         import BALBALAMARKETONEA from '../Imagess/Architecture/Civivc/BALBALA MARKET/BALBALAMARKETONE.avif'
         import u from '../Imagess/Architecture/Civivc/BALBALA MARKET/one.avif'
         import v from '../Imagess/Architecture/Civivc/BALBALA MARKET/two.avif'
         import w from '../Imagess/Architecture/Civivc/BALBALA MARKET/three.avif'
         import x from '../Imagess/Architecture/Civivc/BALBALA MARKET/four.avif'
         import fivev from '../Imagess/Architecture/Civivc/BALBALA MARKET/five.avif'
         import sixv from '../Imagess/Architecture/Civivc/BALBALA MARKET/six.avif'
         import sevenv from '../Imagess/Architecture/Civivc/BALBALA MARKET/seven.avif'
         import eightv from '../Imagess/Architecture/Civivc/BALBALA MARKET/eight.avif'
         import ninev from '../Imagess/Architecture/Civivc/BALBALA MARKET/nine.avif'
         import tenv from '../Imagess/Architecture/Civivc/BALBALA MARKET/ten.avif'
         import elevenv from '../Imagess/Architecture/Civivc/BALBALA MARKET/eleven.avif'
         import twelvev from '../Imagess/Architecture/Civivc/BALBALA MARKET/twelve.avif'
         import thirteenv from '../Imagess/Architecture/Civivc/BALBALA MARKET/thirteen.avif'
         
         //CHINCHPOKLI STATION
         import CHINCHPOKLISTATIONONEA from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/CHINCHPOKLISTATIONONE.avif'
         import aa from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/one.avif'
         import ab from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/two.avif'
         import ac from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/three.avif'
         import ad from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/four.avif'
         import fivew from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/five.avif'
         import sixw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/six.avif'
         import sevenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/seven.avif'
         import eightw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/eight.avif'
         import ninew from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/nine.avif'
         import tenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/ten.avif'
         import elevenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/eleven.avif'
         import twelvew from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/twelve.avif'
         import thirteenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/thirteen.avif'
         import fourteenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/fourteen.avif'
         import fifteenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/fifteen.avif'
         import sixteenw from '../Imagess/Architecture/Civivc/CHINCHPOKLI STATION/sixteen.avif'
         
         
         
         //DINDOSHI
         import DINDOSHIONE from '../Imagess/Architecture/Civivc/DINDOSHI/DINDOSHIONE.avif'
         import ae from '../Imagess/Architecture/Civivc/DINDOSHI/one.avif'
         import af from '../Imagess/Architecture/Civivc/DINDOSHI/two.avif'
         import ag from '../Imagess/Architecture/Civivc/DINDOSHI/three.avif'
         
         
         //MLCP MATUNGA
         import MLCPMATUNGAONE from '../Imagess/Architecture/Civivc/MLCP MATUNGA/MLCPMATUNGAONE.avif'
         import ah from '../Imagess/Architecture/Civivc/MLCP MATUNGA/one.avif'
         import ai from '../Imagess/Architecture/Civivc/MLCP MATUNGA/two.avif'
         import aj from '../Imagess/Architecture/Civivc/MLCP MATUNGA/three.avif'
         
                   //MLCP MUMBADEVI
         import MLCPMUMBADEVIONE from '../Imagess/Architecture/Civivc/MLCP MUMBADEVI/MLCPMUMBADEVIONE.avif'
         import aone from '../Imagess/Architecture/Civivc/MLCP MUMBADEVI/one.avif'
         import atwo from '../Imagess/Architecture/Civivc/MLCP MUMBADEVI/two.avif'
         import athree from '../Imagess/Architecture/Civivc/MLCP MUMBADEVI/three.avif'
         import afour from '../Imagess/Architecture/Civivc/MLCP MUMBADEVI/four.avif'
         
         
         
         
         
         
                             // Architecture Section , Sub Section :- Hospitality
                   //Hearth Stone Arabella
         import HEARTHSTONEARABELLAONE from '../Imagess/Architecture/Hospitality/HEARTH STONE ARABELLA/HEARTHSTONEARABELLAONE.jpg'
         //Iskcon Eco Village
         import ISKCONECOVILLAGEONEH from '../Imagess/Architecture/Hospitality/ISKCON ECO VILLAGE/ISKCONECOVILLAGEONE.jpg'
         
         
         
         
         
                           //Interior Design Section , Sub Section :- Resedential
                   //SIX ZERO TWO APARTMENT
         import SIXZEROTWOAPARTMENTONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/SIXZEROTWOAPARTMENTONE.avif'
         import onea from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/one.avif'
         import twoa from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/two.avif'
         import threea from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/three.avif'
         import foura from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/four.avif'
         import fivea from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/five.avif'
         import sixa from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/six.avif'
         import sevena from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/seven.avif'
         import eighta from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/eight.avif'
         import ninea from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/nine.avif'
         import tena from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/ten.avif'
         import elevena from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/eleven.avif'
         import twelvea from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/twelve.avif'
         import thirteena from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/thirteen.avif'
         import fourteena from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/602 APARTMENT/fourteen.avif'
         
         //Bisen Villa
         import BISENVILLAONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/BISENVILLAONE.avif'
         import oneb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/one.avif'
         import twob from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/two.avif'
         import threeb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/three.avif'
         import fourb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/four.avif'
         import fiveb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/five.avif'
         import sixb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/six.avif'
         import sevenb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/seven.avif'
         import eightb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/eight.avif'
         import nineb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/nine.avif'
         import tenb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/ten.avif'
         import elevenb from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/BISEN VILLA/eleven.avif'
         
                   //Gune Residence
         import GUNERESIDENCEONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/GUNERESIDENCE.avif'
         import onec from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/one.avif'
         import twoc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/two.avif'
         import threec from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/three.avif'
         import fourc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/four.avif'
         import fivec from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/five.avif'
         import sixc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/six.avif'
         import sevenc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/seven.avif'
         import eightc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/eight.avif'
         import ninec from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/nine.avif'
         import tenc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/ten.avif'
         import elevenc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/eleven.avif'
         import twelvec from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/twelve.avif'
         import thirteenc from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/GUNE RESIDENCE/thirteen.avif'
         
         
                   //Hearth Stone Arabella
         import HEARTHSTONEARABELLAONEI from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/HEARTHSTONEONER.avif'
         import oned from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/one.avif'
         import twod from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/two.avif'
         import threed from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/three.avif'
         import fourd from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/four.avif'
         import fived from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/five.avif'
         import sixd from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/six.avif'
         import sevend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/seven.avif'
         import eightd from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/eight.avif'
         import nined from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/nine.avif'
         import tend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/ten.avif'
         import elevend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/eleven.avif'
         import twelved from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twelve.avif'
         import thirteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/thirteen.avif'
         import fourteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/fourteen.avif'
         import fifteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/fifteen.avif'
         import sixteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/sixteen.avif'
         import seventeend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/seventeen.avif'
         import eighteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/eighteen.avif'
         import nineteend from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/nineteen.avif'
         import twentyd from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twenty.avif'
         import twentyoned from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twentyone.avif'
         import twentytwod from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twentytwo.avif'
         import twentythreed from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twentythree.avif'
         import twentyfourd from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twentyfour.avif'
         import twentyfived from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/HEARTH STONE ARABELLA/twentyfive.avif'
         
                   //Jain Residence
         import JAINRESIDENCEONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/JAINRESIDENCEONE.avif'
         import onee from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/one.avif'
         import twoe from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/two.avif'
         import threee from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/three.avif'
         import foure from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/four.avif'
         import fivee from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/five.avif'
         import sixe from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/six.avif'
         import sevene from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/seven.avif'
         import eighte from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/eight.avif'
         import ninee from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/nine.avif'
         import tene from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/ten.avif'
         import elevene from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/eleven.avif'
         import twelvee from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/JAIN RESIDENCE/twelve.avif'
         
                   //Mashrabia Villa
         import MASHRABIAVILLAONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/MASHRABIAVILLAONE.avif'
         import onef from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/one.avif'
         import twof from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/two.avif'
         import threef from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/three.avif'
         import fourf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/four.avif'
         import fivef from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/five.avif'
         import sixf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/six.avif'
         import sevenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/seven.avif'
         import eightf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/eight.avif'
         import ninef from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/nine.avif'
         import tenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/ten.avif'
         import elevenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/eleven.avif'
         import twelvef from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/twelve.avif'
         import thirteenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/thirteen.avif'
         import fourteenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/fourteen.avif'
         import fifteenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/fifteen.avif'
         import sixteenf from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/sixteen.avif'
         import sixteenff from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/MASHRABIA VILLA/seventeen.avif'
         
         
                   //Sunlit Splendor
         import SUNLITSPLENDORONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/SUNLITSPLENDORONE.avif'
         import oneg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/one.avif'
         import twog from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/two.avif'
         import threeg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/three.avif'
         import fourg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/four.avif'
         import fiveg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/five.avif'
         import sixg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/six.avif'
         import seveng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/seven.avif'
         import eightg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/eight.avif'
         import nineg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/nine.avif'
         import teng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/ten.avif'
         import eleveng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/eleven.avif'
         import twelveg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/twelve.avif'
         import thirteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/thirteen.avif'
         import fourteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/fourteen.avif'
         import fifteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/fifteen.avif'
         import sixteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/sixteen.avif'
         import seventeeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/seventeen.avif'
         import eighteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/eighteen.avif'
         import nineteeng from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/nineteen.avif'
         import twentyg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/twenty.avif'
         import twentyoneg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/twentyone.avif'
         import twentytwog from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/twentytwo.avif'
         import twentythreeg from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/SUNLIT SPLENDOR/twentythree.avif'
         
                   //The Art House
         import ARTHOUSEONE from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/ARTHOUSEONE.avif'
         import oneh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/one.avif'
         import twoh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/two.avif'
         import threeh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/three.avif'
         import fourh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/four.avif'
         import fiveh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/five.avif'
         import sixh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/six.avif'
         import sevenh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/seven.avif'
         import eighth from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/eight.avif'
         import nineh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/nine.avif'
         import tenh from '../Imagess/INTERIOR DESIGN/RESIDENTIAL/THE ART HOUSE (VILLA 18)/ten.avif'
         
         
                             //Interior Design Section , Sub Section :- Commercial
         
                   //SEVEN ZERO TWO 
         import SEVENZEROTWOONE from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/SEVENZEROTWOONE.avif'
         import onei from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/one.avif'
         import twoi from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/two.avif'
         import threei from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/three.avif'
         import fouri from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/four.avif'
         import fivei from '../Imagess/INTERIOR DESIGN/COMMERCIAL/70 2/five.avif'
         
                   //H Office
         import HOFFICEONE from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/HOFFICEONE.avif'
         import onej from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/one.avif'
         import twoj from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/two.avif'
         import threej from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/three.avif'
         import fourj from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/four.avif'
         import fivej from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/five.avif'
         import sixj from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/six.avif'
         import sevenj from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/seven.avif'
         import eightj from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/eight.avif'
         import ninej from '../Imagess/INTERIOR DESIGN/COMMERCIAL/HOFFICE/nine.avif'
         
                   //The Studio
         import THESTUDIOONE from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/THESTUDIOONE.avif'
         import onek from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/one.avif'
         import twok from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/two.avif'
         import threek from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/three.avif'
         import fourk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/four.avif'
         import fivek from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/five.avif'
         import sixk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/six.avif'
         import sevenk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/seven.avif'
         import eightk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/eight.avif'
         import ninek from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/nine.avif'
         import tenk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/ten.avif'
         import elevenk from '../Imagess/INTERIOR DESIGN/COMMERCIAL/THE STUDIO/eleven.avif'
         
         
         
                             //Interior Design Section , Sub Section :- Hospitality
                   //Art House
         import ARTHOUSEONEH from '../Imagess/INTERIOR DESIGN/HOSPITALITY/ART HOUSE ( VILLA 18 )/ARTHOUSEONE.jpg'
                   //Hearth Stone Arabella
         import HEARTHSTONEONEH from '../Imagess/INTERIOR DESIGN/HOSPITALITY/HEARTH STONE ARABELLA/HEARTHSTONEONEH.jpg'
                   //Sunlit Splendor
         import SUNLITSPLENDORONEH from '../Imagess/INTERIOR DESIGN/HOSPITALITY/SUNLIT SPLENDOR/SUNLITSPLENDORONE.jpg'
         
         
         
                             //Interior Design Section , Sub Section :- Institutional
                   //Nsm School
         import NSMSCHOOLONE from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/NSMSCHOOLone.avif'
         import onep from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/one.avif'
         import twop from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/two.avif'
         import threep from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/three.avif'
         import fourp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/four.avif'
         import fivep from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/five.avif'
         import sixp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/six.avif'
         import sevenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/seven.avif'
         import eightp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/eight.avif'
         import ninep from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/nine.avif'
         import tenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/ten.avif'
         import elevenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/eleven.avif'
         import twelvep from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/twelve.avif'
         import thirteenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/thirteen.avif'
         import fourteenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/fourteen.avif'
         import fifteenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/fifteen.avif'
         import sixteenp from '../Imagess/INTERIOR DESIGN/INSTITUTIONAL/NSM SCHOOL/sixteen.avif'
         
         
         
                             //Interior Design Section , Sub Section :- Amenities
                   //Iccr Digital   ,,,, only one image available
                   //Trupti 
         import TRUPTII from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/TRUPTIONE.avif'
         import oner from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/one.avif'
         import twor from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/two.avif'
         import threer from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/three.avif'
         import fourr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/four.avif'
         import fiver from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/one.avif'
         import sixr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/two.avif'
         import sevenr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/three.avif'
         import eightr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/four.avif'
         import niner from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/one.avif'
         import tenr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/two.avif'
         import elevenr from '../Imagess/INTERIOR DESIGN/AMENITIES/TRUPTI/three.avif'
         
         
         
         
         
                   // LANDSCAPE SECTION WITH ALL SUB SECTION
               //Bank Of India
         import BANKOFINDIAONE from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/BANKOFINDIAONE.avif'
         import onel from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/one.avif'
         import twol from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/two.avif'
         import threel from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/three.avif'
         import fourl from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/four.avif'
         import fivel from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/five.avif'
         import sixl from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/six.avif'
         import sevenl from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/seven.avif'
         import eightl from '../Imagess/LANDSCAPE/BANK OF INDIA BKC/eight.avif'
         
               //JIJAMATA UDHYAN
         import JIJAMATAUDHYANONE from '../Imagess/LANDSCAPE/JIJAMATA UDHYAN/JIJAMATAUDHYANONE.png'
               //TRUPTI
         import TRUPTIONE from '../Imagess/LANDSCAPE/TRUPTI/TRUPTIONE.avif'
         import onem from '../Imagess/LANDSCAPE/TRUPTI/one.avif'
         import twom from '../Imagess/LANDSCAPE/TRUPTI/two.avif'
         import threem from '../Imagess/LANDSCAPE/TRUPTI/three.avif'
         import fourm from '../Imagess/LANDSCAPE/TRUPTI/four.avif'
         import fivem from '../Imagess/LANDSCAPE/TRUPTI/five.avif'
         import sixm from '../Imagess/LANDSCAPE/TRUPTI/six.avif'
         import sevenm from '../Imagess/LANDSCAPE/TRUPTI/seven.avif'
         import eightm from '../Imagess/LANDSCAPE/TRUPTI/eight.avif'
         import ninem from '../Imagess/LANDSCAPE/TRUPTI/nine.avif'
         import tenm from '../Imagess/LANDSCAPE/TRUPTI/ten.avif'
         import elevenm from '../Imagess/LANDSCAPE/TRUPTI/eleven.avif'
         
         
         
                   // PLANNING SECTION WITH ALL SUB SECTION
         import BALBALAMARKETONE from '../Imagess/PLANNING/BALBALA MARKET/BALBALAMARKETONE.jpg'
         import CHINCHPOKLISTATIONONE from '../Imagess/PLANNING/CHINCHPOKLI STATION/CHINCHPOKLISTATIONONE.jpg'
         import ISKCONECOVILLAGEONE from '../Imagess/PLANNING/ISKCON ECO VILLAGE/ISKCON-ECOVILLAGEONE.jpg'
         
                   // FACADE SECTION WITH ALL SUB SECTION
               //HINDU GHYMKHANA
         import HINDUGHYMKHANAONE from '../Imagess/FACADE/HINDU GHYMKHANA/HINDUGHYMKHANAONE.avif'
         import onen from '../Imagess/FACADE/HINDU GHYMKHANA/one.avif'
         import twon from '../Imagess/FACADE/HINDU GHYMKHANA/two.avif'
         import threen from '../Imagess/FACADE/HINDU GHYMKHANA/three.avif'
         import fourn from '../Imagess/FACADE/HINDU GHYMKHANA/four.avif'
         import fiven from '../Imagess/FACADE/HINDU GHYMKHANA/five.avif'
         import sixn from '../Imagess/FACADE/HINDU GHYMKHANA/six.avif'
         import sevenn from '../Imagess/FACADE/HINDU GHYMKHANA/seven.avif'
         import eightn from '../Imagess/FACADE/HINDU GHYMKHANA/eight.avif'
         import ninen from '../Imagess/FACADE/HINDU GHYMKHANA/nine.avif'
         import tenn from '../Imagess/FACADE/HINDU GHYMKHANA/ten.avif'
         
               //NASIK OFFICE
         import NASIKOFFICE from '../Imagess/FACADE/NASIK OFFICE/NASIKOFFICEONE.avif'
         import oneo from '../Imagess/FACADE/NASIK OFFICE/one.avif'
         import twoo from '../Imagess/FACADE/NASIK OFFICE/two.avif'
         import threeo from '../Imagess/FACADE/NASIK OFFICE/three.avif'
         import fouro from '../Imagess/FACADE/NASIK OFFICE/four.avif'
               //Trupti
         import TRUPTIONEF from '../Imagess/FACADE/TRUPTI/TRUPTIONE.avif'
         import oneq from '../Imagess/FACADE/TRUPTI/one.avif'
         import twoq from '../Imagess/FACADE/TRUPTI/two.avif'
         import threeq from '../Imagess/FACADE/TRUPTI/three.avif'
         import fourq from '../Imagess/FACADE/TRUPTI/four.avif'
         import fiveq from '../Imagess/FACADE/TRUPTI/five.avif'
         import sixq from '../Imagess/FACADE/TRUPTI/six.avif'
         import sevenq from '../Imagess/FACADE/TRUPTI/seven.avif'
         
         
         
         
         
         
         
         
         
         
         const Portfolidata = [
         
         
         
                 
                           // INTERIOR DESIGN Section , Sub Section :- Resedential
                           {
         
                             title: "602 APARTMENT",
                             slugs: "/interior_design-residential-602apartment",
                             category: ["interior_design"],
                             subCategory: ["residential"],
                             imagesList: [SIXZEROTWOAPARTMENTONE,onea,twoa,threea,foura,fivea,sixa,sevena,eighta,ninea,tena,elevena,twelvea,thirteena,fourteena],
                             location:"MUMBAI, MAHARASHTRA",
                             area:"700 SQFT",
                             status:'BUILT',
                             desc: "The 602 Apartment project reimagines a typical 2BHK into a highly personalized extension of the client’s existing residence, with a strong Art Deco influence reflecting the client’s passion for cinema. The design is anchored by bespoke elements throughout, including meticulously crafted inlay marble and woodwork that add an elevated sense of detail. The lounge area, adjacent to the terrace, showcases intricate inlay patterns, enhancing the spatial narrative. A bold tension is introduced with the use of contrasting colors—most notably, the deep blue cabinetry and a striking yellow sofa—that bring visual depth and enrich the overall composition. Each space is thoughtfully curated to align with the client’s distinct tastes, from the calming, pastel-hued bedroom to the subtle, floral-patterned headboard that offers a touch of whimsy. Vintage-inspired lighting, along with custom furnishings, enhances the space’s intimate yet luxurious atmosphere. The design seamlessly integrates contemporary aesthetics with nuanced, bespoke touches, creating a cohesive yet layered narrative that speaks to the client’s love for classic film and timeless design language.",
                           },
                           {
                 
                             title: "BISEN VILLA",
                             slugs: "/interior_design-residential-bisenvilla",
                             category: ["interior_design"],
                             subCategory: ["residential"],
                             imagesList: [BISENVILLAONE,oneb,twob,threeb,fourb,fiveb,sixb,sevenb,eightb,nineb,tenb,elevenb],
                             location:"DEHRADUN",
                             area:"1500 sqft",
                             status:'BUILT',
                             desc: "The Bisen Villa renovation project in Dehradun elegantly merges old-world charm with contemporary luxury. By retaining select original details, the design emphasizes warmth and comfort through a palette of neutral tones and inviting, plush furniture. English molding and decorative niches enhance the architectural character of the space, blending seamlessly with the villa's original elements. The subtle play of textures, refined materials, and soft lighting creates a welcoming, sophisticated atmosphere, adding depth and a sense of timeless elegance to the interior.	",
                           },
                           {
                 
                             title: "GUNE RESIDENCE",
                             slugs: "/interior_design-residential-guneresidence",
                             category: ["interior_design"],
                             subCategory: ["residential"],
                             imagesList: [GUNERESIDENCEONE,onec,twoc,threec,fourc,fivec,sixc,sevenc,eightc,ninec,tenc,elevenc,twelvec,thirteenc],
                             location:"MUMBAI, MAHARASHTRA",
                             area:"950 sqft",
                             status:'BUILT',
                             desc: "The Gune Residence is a thoughtfully designed 3 BHK apartment that blends modern sensibilities with inviting, homely elements. The design emphasizes warmth and comfort, with soft pastel tones and textures across the living spaces. The use of wooden paneling and cabinetry brings a rich, natural warmth to the interiors, complemented by subtle design details like the soft tufted headboards and layered textiles. In the bedroom, the delicate floral patterns of the quilt and cushions, combined with the earthy wooden paneling, add a cozy yet sophisticated feel. The muted color palette, accented with soft greens and blues, creates a serene environment ideal for relaxation. Each room in the apartment carries this theme of warmth, with personalized touches and functional furniture, making the home not just aesthetically pleasing but also comfortable and welcoming.		",
                           },
                           {
                 
                             title: "HEARTHSTONE INTERIORS",
                             slugs: "/interior_design-residential-hearthstoneinteriors",
                             category: ["interior_design"],
                             subCategory: ["residential","hospitalityi"],
                             imagesList: [HEARTHSTONEARABELLAONEI,threed,fourd,oned,twod,fived,sixd,sevend,eightd,nined,tend,elevend,twelved,thirteend,fourteend,fifteend,sixteend,seventeend,eighteend,nineteend,twentyd,twentyoned,twentytwod,twentythreed,twentyfourd,twentyfived],
                             location:"ALIBAUG",
                             area:"3600 SQFT",
                             status:'BUILT',
                             desc: "This Alibaug villa offers a seamless blend of modern design, cultural storytelling, and comfort. The ground floor welcomes guests with a spacious living area featuring bespoke wall panels that narrate Alibaug's rich culture, while the open-concept kitchen maintains simplicity and functionality. Natural materials like teak wood highlight the metal staircase, creating a harmonious flow. Two bedrooms open to the pool, offering serene views, while the others face the entry, each with neutral, earthy tones that balance luxury with coziness. The first floor features an open-plan layout with designated areas for various activities, including a lounge, gaming zone, and dining space. A standout bar with concrete panels and leaf motifs complements the earthy theme. The spacious balcony, with rain chains and lush views, offers a peaceful retreat, especially during the monsoons. This villa, designed for all seasons, combines thoughtful design, multifunctional spaces, and cultural elements, providing an ideal blend of modern luxury and natural serenity.",
                           },
                           {
                 
                             title: "JAIN RESIDENCE",
                             slugs: "/interior_design-residential-jainresidence",
                             category: ["interior_design"],
                             subCategory:["residential"],
                             imagesList: [JAINRESIDENCEONE,onee,twoe,threee,foure,fivee,sixe,sevene,eighte,ninee,tene,elevene,twelvee],
                             location:"MUMBAI, MAHARASHTRA",
                             area:"650 sqft",
                             status:'BUILT',
                             desc: "This 2BHK apartment exudes a warm, inviting ambiance with a harmonious blend of modern and traditional design. Key pieces like the traditional armchairs, dining set, and mandir were already part of the space, inspiring a concept focused on light wooden tones and textured finishes. The design integrates handcrafted and artistic decor elements to complement, rather than overpower, the ethnic accents. Every detail, from the lighting to the decor, aligns with this central idea, enhancing the charm of the space. The furniture features subtle yet prominent ornamentation, giving the apartment a modern twist while maintaining its cultural essence, bringing the entire design together seamlessly.				",
                           },
                           {
                 
                             title: "MASHRABIA VILLA INTERIORS",
                             slugs: "/interior_design-residential-mashrabiavillainteriors",
                             category: ["interior_design"],
                             subCategory: ["residential"],
                             imagesList: [MASHRABIAVILLAONE,onef,twof,threef,fourf,fivef,sixf,sevenf,eightf,ninef,tenf,elevenf,twelvef,thirteenf,fourteenf,fifteenf,sixteenf,sixteenff],
                             location:"DJIBOUTI",
                             area:"15000 sqft",
                             status:'BUILT',
                             desc: "This Ground Plus 2 Floor Villa in Djibouti draws inspiration from Arabesque design, combined with modern influences from Dubai. The interiors feature intricate geometric patterns, rich textures, and metallic accents that reflect traditional Middle Eastern elegance. These are balanced with clean lines, minimalist furniture, and contemporary finishes like sleek marble and modern lighting. The design blends the cultural richness of Arabesque elements with Dubai's modern luxury, creating a space that feels opulent yet suited for contemporary living. It's a harmonious fusion of tradition and modernity.",
                           },
                           {
                 
                             title: "SUNLIT SPLENDOR",
                             slugs: "/interior_design-residential-sunlitsplendor",
                             category: ["interior_design"],
                             subCategory: ["residential","hospitalityi"],
                             imagesList: [SUNLITSPLENDORONE,oneg,twog,threeg,fourg,fiveg,sixg,seveng,eightg,nineg,teng,eleveng,twelveg,thirteeng,fourteeng,fifteeng,sixteeng,seventeeng,eighteeng,nineteeng,twentyg,twentyoneg,twentytwog,twentythreeg],
                             location:"ALIBAUG",
                             area:"2600 sqft",
                             status:'BUILT',
                             desc: "This hospitality project, in collaboration with the Stay Vista team, was designed to create Zen-inspired homes that offer the comfort of a cozy residence. The design draws influence from the serene Zen homes of Malibu, with blue accents reflecting the coastal location and organic touches inspired by Bali’s lush greenery. The goal was to create a harmonious space where every element blends seamlessly, avoiding anything too bold or out of place. Every detail, from the handcrafted furniture to the carefully chosen fabrics and décor, was selected to enhance the overall sense of calm and comfort. The four bedrooms maintain the same design concept, with subtle variations in headboard styles, lighting, and furniture pieces, ensuring a cohesive yet personalized feel. The result is a peaceful, balanced environment that feels both luxurious and inviting.",
                           },
                           {
                 
                             title: "ART HOUSE",
                             slugs: "/interior_design-residential-arthouse",
                             category: ["interior_design"],
                             subCategory: ["residential","hospitalityi"],
                             imagesList: [ARTHOUSEONE,oneh,twoh,threeh,fourh,fiveh,sixh,sevenh,eighth,nineh,tenh],
                             location:"ALIBAUG",
                             area:"2050 sqft",
                             status:'BUILT',
                             desc: "This ground-plus-one villa in Alibaug blends contemporary design with classic elements, offering a warm, inviting, and art-filled retreat. The double-height living room exudes a sense of airiness and grandeur, featuring neutral-toned upholstery and custom wooden furniture that adds to the refined yet comfortable aesthetic. Handmade jute lights from Rajasthan beautifully frame the living area, adding a touch of rustic elegance. Under the staircase, a cleverly designed bar seamlessly transforms into an indoor dining area, with a showcase cabinet below, providing versatility. The clients, art collectors with a passion for rustic pieces, desired interiors that would accommodate their art and paintings. Each element in the home carries a sense of history, reflecting their personal taste. The villa’s three bedrooms are simple yet elegant, each featuring cozy wooden elements and intimate nooks. The family and reading space serves as the heart of the villa, with ambient lighting, carefully curated artwork, and inviting furniture, offering the perfect corner to relax. This home masterfully integrates art, history, and design, creating a tranquil retreat that embraces Alibaug's coastal charm.",
                           },
                 
                 
                 
         
               // Architecture Section With All Sub Section
         
                   // Architecture Section , Sub Section :- Resedential
                   {
         
                     title: "Ashok Vatika",
                     slugs: "/architecture-residential-ashokvatika",
                     category:["architecture"],
                     subCategory: ['residential'],
                     imagesList: [ashokvatikaone,a,b,c,d,fiverr,sixrr,sevenrr,eightrr],
                     location:"AHEMDABAD",
                     area:"7500 sqft",
                     status:'UNBUILT',
                     desc: "This contemporary villa in Ahmedabad was designed with the intention of avoiding a singular solid mass, opting instead for fragmented volumes connected by covered walkways and colonnades along the periphery. This approach creates a clear distinction between served and service spaces, with the colonnades providing shade for the serviced areas. The fragmentation not only enhances spatial flow but also allows the incorporation of water features and greenery, fostering a microclimate that aligns with the region's climatic conditions. The villa’s design speaks a modern language with its clean lines and segmented openings, but subtle ethnic elements are incorporated through minimal ornamentation. The circulation and narrative of the spaces are key to the design, offering an immersive experience that would be lost with a monolithic structure. Given the client's aspirations and the local context, this fragmented design concept was an ideal choice. The integration of green spaces and perforated screens enriches the living experience, perfectly complementing Ahmedabad's climate.",
                   },
                   {
         
                     title: "HEARTH STONE",
                     slugs: "/architecture-residential-hearthstone",
                     category: ['architecture'],
                     subCategory: ['residential','hospitality'],
                     imagesList: [HEARTHSTONEONE,f,h,e,g,fives,sixs,sevens,eights,nines,tens,elevens,twelves,thirteens], 
                     location:"ALIBAUG, MAHARASHTRA",
                     area:"3600 SQFT",
                     status:'BUILT',
                     desc: "This striking private villa captivates with its iconic house silhouette, blending modern luxury with timeless comfort. Its front elevation, marked by expansive gabled windows and reflective surfaces, creates a welcoming, stylish atmosphere. A brick-clad veranda adds earthy texture, contrasting beautifully with the clean lines of the architecture, offering a grand yet understated entryway. Rising to two stories, the villa’s design balances interior and exterior spaces seamlessly. Large gabled windows flood the home with natural light, framing views of lush greenery. The pool-facing elevation at the rear exudes sophistication, with the reflective waters enhancing the sense of tranquility and luxury. Rooted in balance and symmetry, this villa’s geometric clarity and minimalist elegance reflect a harmonious blend of modernity and tradition. It offers a perfect retreat where comfort and grandeur coexist, making it a masterclass in contemporary architecture. Whether viewed from the welcoming front or serene rear, the villa is an intimate, luxurious sanctuary.", 
                   },
                   {
         
                     title: "MASHRABIA VILLA DJIBOUTI",
                     slugs: "/architecture-residential-mashrabiavilladjibouti",
                     category: ["architecture"],
                     subCategory: ["residential"],
                     imagesList: [MASHRABIAONE,mone,mtwo,threet,fourt,mfive],
                     location:"DJIBOUTI, AFRICA",
                     area:"15000 sqft",
                     status:'IN PROGRESS',
                     desc: "This G+2 villa in Djibouti embodies a seamless fusion of modern design and Arabesque architectural elements, creating a sophisticated yet culturally resonant residence. The exterior features clean, contemporary lines enhanced by intricate lattice screens and traditional geometric patterns that pay homage to classical Islamic design. These mashrabiya-inspired screens not only provide privacy but also regulate natural light and ventilation, keeping the interiors cool in Djibouti's warm climate. The villa’s facade, with its light-colored stone and perforated metal panels, creates an airy, open ambiance while maintaining a strong, solid structure. The spacious balconies are framed by arches, reinforcing the Arabesque aesthetic while offering outdoor lounging spaces. The interplay of shadow and light created by the detailed latticework further emphasizes the villa's elegance, blending modernity with a deep respect for traditional craftsmanship. The design reflects both contemporary luxury and a timeless appreciation for regional architectural heritage, making it a standout example of modern Arabesque architecture in Djibouti.",
                   },
                   {
         
                     title: "TWIN VILLAS",
                     slugs: "/architecture-residential-twinvillas",
                     category: ["architecture"],
                     subCategory: ["residential"],
                     imagesList: [TWINONE,i,j,k,l],
                     location:"DJIBOUTI, AFRICA",
                     area:"18000 sqft",
                     status:'IN PROGRESS',
                     desc: "This  twin villa in Djibouti offers an exquisite blend of modern design and functional living, tailored for the rental market. The villa’s clean lines and contemporary façade are accented by intricate geometric patterns, creating a striking balance of form and function. The ground and first floors are dedicated to spacious living areas, ensuring comfort and privacy for residents. Large windows and open balconies enhance the connection between indoor and outdoor spaces, allowing for ample natural light and ventilation. The second-floor terrace is a key highlight, designed as a luxurious lounging area perfect for relaxation or entertaining. Covered with wooden pergolas, it provides shade and privacy, making it a serene space to enjoy Djibouti’s warm climate. The overall design of the villa is not only visually appealing but also ensures a comfortable, modern lifestyle for tenants, with all amenities thoughtfully integrated into the layout. This villa is ideal for those seeking a premium rental experience in Djibouti.",
                   },
         
                   // Architecture Section , Sub Section :- Cultural
                   {
         
                     title: "BUDDHA BHUMI",
                     slugs: "/architecture-cultural-buddha-bhumi",
                     category: ["architecture","planning"],
                     subCategory: ['cultural',""],
                     imagesList: [BUDDHABHUMI,BUDDHABHUMIimgtwo,BUDDHABHUMIimgone,BUDDHABHUMIimgthree,BUDDHABHUMIimgfour,BUDDHABHUMIimgfive,BUDDHABHUMIimgsix,BUDDHABHUMIimgseven,BUDDHABHUMIimgeight,BUDDHABHUMIimgnine,BUDDHABHUMIimgten,BUDDHABHUMIimgeleven,BUDDHABHUMIimgtwelve,BUDDHABHUMIimgthirteen,BUDDHABHUMIimgfourteen,BUDDHABHUMIimgfifteen,BUDDHABHUMIimgsixteen,BUDDHABHUMIimgsixteenn],
                     location:"Khushinagar",
                     area:"5 acres",
                     status:'UNBUILT',
                     desc: `"Buddha Bhumi" is a thoughtfully designed public space and memorial that celebrates India's  profound role as the birthplace of Buddhism, the land where Gautama Buddha attained enlightenment and shared his wisdom with the world. The architectural design of this memorial captures the transformative journey of the Buddha, from his initial quest for truth to his moment of enlightenment and his subsequent teachings. As visitors move through the space, they are symbolically guided along the path that Buddha himself walked. The entry is marked by a descent, reminiscent of the inner search that led Buddha toward enlightenment, while the open, expansive area at the end signifies the light and clarity achieved after his awakening. The clean, minimalistic use of concrete contrasts with natural elements like greenery, water, and the serene figure of Buddha, creating a space of reflection and peace. The design is intended to evoke a sense of spiritual discovery and contemplation, allowing visitors to engage with the rich heritage of Buddhism and understand the significance of India as the land from which this globally influential religion originated. Through its minimalist architecture and thoughtful spatial narrative, ""Buddha Bhumi"" offers an immersive journey into the life and teachings of the Buddha, inviting both spiritual reflection and cultural appreciation.`,
                   },
                   {
         
                     title: "ISKCON ECO VILLAGE ",
                     slugs: "/architecture-cultural-iskconecovillage",
                     category: ["architecture","planning"],
                     subCategory: ['cultural','hospitality',""],
                     imagesList: [ISKCONECOVILLAGEONEA,q,r,s,t,fiveu,sixu,sevenu,eightu,nineu,tenu],
                     location:"TALASHREE, MAHARASHTRA",
                     area:"23 acres",
                     status:'IN PROGRESS',
                     desc: 'Based on the principles of vedic living, under the Gaudiya Vaishnav parampara, practicing and preaching bhakti yoga, this eco-village aspires to be self sustaining model for spiritual living. Life is based around 3 primary activities- Goshala maintenance, farming and temple service. The goshala (cow shed}, a small temple, living quarters and other structures already existed on the site. ISKCON aspired to add a vedic school, community dining/congregation space, kitchen, Guest houses and a new temple complex + cultural centre, with construction planned in multiple phases. The vedic village is a simple and quaint complex to be built with a purpose of living a life devoted to bhakti yoga, rooted in the achintya bheda bhed philosophy. The Architecture is simple built to facilitate this and resonate the "simple living, high thinking" motto. The structures are not meant to stand out but rather blend into the surroundings and be unassuming. The focus of this project was the placement, orientation, geometry and the quality of spaces and not the ornamentation and decoration. Those would be incorporated by the inhabitants of the space based on their cultural influences.',
                   },{
         
                     title: "JIJAMATA UDHYAN",
                     slugs: "/architecture-cultural-jijamataudhyan",
                     category: ["architecture","landscape"],
                     subCategory: ['cultural',""],
                     imagesList: [JIJAMATAUDHYANONEA,one,two,three,four ],
                     location:"MAHARASHTRA",
                     area:"5.4 acres",
                     status:'UNBUILT',
                     desc: "Jijamata Udayan is a revitalized public garden and memorial dedicated to Jijamata, the mother of Chhatrapati Shivaji Maharaj, honoring her legacy as a nurturing figure and strategic advisor. The design features public pockets throughout the space, including serene canopied meditation pods and a tranquil zen garden, fostering both intimate reflection and communal engagement. At the center lies a small museum showcasing armor, scripts, and tools from Shivaji's era, along with a detailed map of the forts he conquered, connecting visitors to Maratha history. The landscape is thoughtfully crafted with diverse experiences, providing quiet alcoves for solitude and expansive areas for gatherings, while native flora enhances ecological sustainability. Ultimately, Jijamata Udayan is a dynamic public space that invites reflection on Jijamata's wisdom and celebrates the cultural heritage of the Maratha Empire, inspiring a sense of Jagruti and a deeper understanding of their values.",
                     
                   },
         
         
         
                   // Architecture Section , Sub Section :- Civivc
                   {
         
                     title: "BALBALA MARKET",
                     slugs: "/architecture-civic-balbalamarket",
                     category: ["architecture","planning"],
                     subCategory: ["civic"],
                     imagesList: [BALBALAMARKETONEA,u,v,w,x,fivev,sixv,sevenv,eightv,ninev,tenv,elevenv,twelvev,thirteenv],
                     location:"DJIBOUTI",
                     area:"80,000 sqft",
                     status:'IN PROGRESS',
                     desc: "The Balbala Public Market in Djibouti is designed to serve as a vibrant community hub, integrating local commerce with a thoughtfully planned public space. The market features modular structures with an open layout that promotes fluid movement and interaction between vendors and visitors. The use of natural materials like thatch roofing creates a connection to local building traditions, while the green pockets within the market's layout offer spaces for relaxation and socializing. The architecture balances function and aesthetics, with sustainable design elements like roof gardens that add greenery and enhance the microclimate. This market is a dynamic blend of commercial activity and community gathering, fostering a sense of place in the heart of Balbala.",
                   },
                   {
         
                     title: "CHINCHPOKLI STATION",
                     slugs: "/architecture-civic-chinchpoklistation",
                     category: ["architecture","planning"],
                     subCategory: ["civic"],
                     imagesList: [CHINCHPOKLISTATIONONEA,aa,ab,ac,ad,fivew,sixw,sevenw,eightw,ninew,tenw,elevenw,twelvew,thirteenw,fourteenw,fifteenw,sixteenw],
                     location:"MUMBAI, MAHARASHTRA",
                     area:"58,000 sqft",
                     status:'IN PROGRESS',
                     desc: "The Chinchpokli Station pilot project introduces a significant upgrade in infrastructure, bringing it closer to the look and feel of modern metro stations. A new concourse level is added, enhancing the commuter experience by facilitating better circulation and access. The station's façade is designed with functionality in mind, incorporating a unique system that filters harsh sunlight while allowing natural light to permeate throughout the day. This ensures the station remains well-lit without relying heavily on artificial lighting, thereby reducing energy consumption. The overall design reflects a forward-thinking approach to urban transit infrastructure, aiming for sustainability and efficiency.		",
                   },
                   {
         
                     title: "DINDOSHI",
                     slugs: "/architecture-civic-dindoshi",
                     category: ["architecture"],
                     subCategory: ["civic"],
                     imagesList: [DINDOSHIONE,ae,af,ag],
                     location:"Mumbai",
                     area:"-",
                     status:'UNBUILT',
                     desc: "This high-rise project for MMRDA exemplifies modern architecture, combining functionality with an elegant aesthetic. The striking glass façade allows abundant natural light to flood the interiors, creating an open and inviting atmosphere while offering expansive city views. A central void strategically divides the structure, adding architectural intrigue and enhancing cross-ventilation for improved sustainability. Designed to harmonize with its urban surroundings, the building reflects a thoughtful balance between innovative design and environmental consciousness, making it a landmark addition to Mumbai's skyline.				",
                   },
                   {
         
                     title: "MLCP MATUNGA ROBOTIC PARKING TOWER",
                     slugs: "/architecture-civic-mlcpmatunga",
                     category: ["architecture"],
                     subCategory: ["civic"],
                     imagesList: [MLCPMATUNGAONE,ah,ai,aj],
                     location:"MUMBAI, MAHARASHTRA",
                     area:"16,000 sqmt",
                     status:'IN PROGRESS',
                     desc: "The Matunga Parking Tower near the railway station is a contemporary urban structure seamlessly integrating advanced robotic parking technology with traditional South Indian architectural motifs. The façade draws inspiration from a vibrant temple-style gopuram, embodying cultural reverence while delivering modern functionality. Designed to be a visual landmark, the tower’s intricate detailing and bold motifs are visible even from passing trains, enhancing its presence within the urban fabric. The tower not only optimizes parking efficiency through automation but also serves as an iconic statement, blending the heritage of South Indian design with cutting-edge infrastructure solutions.				",
                   },
                   {
         
                     title: "MLCP MUMBA DEVI ROBOTIC PARKING TOWER",
                     slugs: "/architecture-civic-mlcpmumbadevi",
                     category: ["architecture"],
                     subCategory: ["civic"],
                     imagesList: [MLCPMUMBADEVIONE,aone,atwo,athree,afour],
                     location:"MUMBAI, MAHARASHTRA",
                     area:"12,000 sqmt",
                     status:'IN PROGRESS',
                     desc: "The Mumbadevi Robotic Parking Tower, a 14-floor structure located adjacent to the iconic Mumba Devi Temple complex, is designed with a minimalist approach to respect the temple's heritage. The clean and understated architecture harmonizes with the surrounding old Bombay buildings, carefully choosing materials that blend seamlessly into the area's historic aesthetic. The tower complements the cultural fabric of the neighborhood without overwhelming the heritage architecture, providing a modern solution to urban parking while preserving the integrity of its surroundings.				",
                   },
         
         
         
         
         
         
                   // Architecture Section , Sub Section :- Hospitality
                   // {
         
                   //   title: "HEARTH STONE ARABELLA ",
                   //   slugs: "/architecture-hospitality-hearthstonearabella",
                   //   category: "architecture",
                   //   subCategory: "hospitality",
                   //   imagesList: [HEARTHSTONEARABELLAONE],
                 
                   //   desc: "",
                   // },
                   // {
         
                   //   title: "ISKCON ECO VILLAGE ",
                   //   slugs: "/architecture-hospitality-iskconecovillage",
                   //   category: "architecture",
                   //   subCategory: ["hospitality"],
                   //   imagesList: [ISKCONECOVILLAGEONEH],
                 
                   //   desc: "",
                   // },
         
         
         
         
         
         
                   // INTERIOR DESIGN Section With All Sub Section
         
         
         
         
                           // INTERIOR DESIGN Section , Sub Section :- Commercial
                   {
         
                     title: "70/2 ",
                     slugs: "/interior_design-commercial-702",
                     category: ["interior_design"],
                     subCategory: ["commercial"],
                     imagesList: [SEVENZEROTWOONE,onei,twoi,threei,fouri,fivei],
                     location:"Mumbai ",
                     area:"450 sqft",
                     status:'BUILT',
                     desc: "This office space for a wedding photographer was thoughtfully designed with Vastu principles in mind, ensuring a harmonious and positive energy flow throughout. The goal was to create a fresh, clean, and mess-free environment that would inspire creativity while maintaining a functional workspace. Every furniture piece was custom-made, tailored to meet the specific requirements, contributing to the sleek and uncluttered look of the space. Considering the photographer's extensive use of technical equipment, one of the main challenges was effectively managing the clutter of wires, large CPUs, and the potential disorganization they could cause.To address this, the furniture was designed with great attention to detail, ensuring that all devices, cables, and operational needs were hidden skillfully without compromising on functionality. The cleverly integrated design allows for seamless access to technical equipment while maintaining a minimalist and clean aesthetic. Additionally, the inclusion of soft pastel hues, ergonomic furniture, and natural elements like indoor plants brings a sense of freshness and calmness to the workspace. The result is a balanced and vibrant environment that caters perfectly to both the technical and creative needs of the wedding photographer, offering an efficient and aesthetically pleasing space to work and thrive in.",
                   },
                   {
         
                     title: "H office ",
                     slugs: "/interior_design-commercial-hoffice",
                     category: ["interior_design"],
                     subCategory: ["commercial"],
                     imagesList: [HOFFICEONE,threej,fourj,onej,twoj,fivej,sixj,sevenj,eightj,ninej],
                     location:"Mumbai",
                     area:"1200 sqft",
                     status:'BUILT',
                     desc: "This office space, designed for a metal etching and plate factory, seamlessly blends industrial aesthetics with functional efficiency. The frosted glass panels with intricate geometric metal patterns provide an elegant yet robust partition, adding a sense of privacy while maintaining a visual connection to the adjoining areas. The subtle incorporation of industrial elements like exposed pipes and metal frames highlights the factory's core business while still presenting a modern and sleek workspace. The workspace itself is kept minimal, with custom-made cabinetry that maximizes storage without overwhelming the space. A smooth wooden desk offers ample working surface, while the dark, muted tones of the furniture create a professional and understated environment. Accents like the potted plants and delicate floral arrangements add a refreshing touch, contrasting with the industrial vibe and bringing a sense of calm and greenery into the space. Soft, ambient lighting further enhances the workspace, illuminating the intricate details of the frosted designs and creating a welcoming atmosphere. Overall, this office strikes the perfect balance between functionality and design, reflecting the craftsmanship and precision inherent in the metal etching industry.",
                   },
                   {
         
                     title: "THE STUDIO ",
                     slugs: "/interior_design-commercial-thestudio",
                     category: ["interior_design"],
                     subCategory: ["commercial"],
                     imagesList: [THESTUDIOONE,onek,twok,threek,fourk,fivek,sixk,sevenk,eightk,ninek,tenk,elevenk],
                     location:"Mumbai",
                     area:"450 sqft",
                     status:'BUILT',
                     desc: "This 450 sqft architectural studio maximizes space with a warm, inviting design inspired by Scandinavian minimalism. Pine partitions and wood flooring create a natural, open feel, while large windows bring in ample light, boosting productivity and well-being. The low ceiling is offset by minimalistic lighting and curved edges, giving the studio an airy flow. Custom-fitted Ikea furniture ensures functionality without clutter, and the reception area, with its arched ceiling, adds a welcoming touch. Each zone is thoughtfully designed, blending function and comfort to foster creativity in an efficient, compact workspace.",
                   },
         
         
         
         
         
         
                 // INTERIOR DESIGN Section , Sub Section :- Hospitality
                 // {
         
                 //   title: "ART HOUSE (VILLA 18)",
                 //   slugs: "/interior_design-hospitality-arthouse",
                 //   category: "interior_design",
                 //   subCategory: "hospitality",
                 //   imagesList: [ARTHOUSEONEH],
               
                 //   desc: "",
                 // },
                 // {
         
                 //   title: "HEARTH STONE ARABELLA",
                 //   slugs: "/interior_design-hospitality-hearthstone",
                 //   category: "interior_design",
                 //   subCategory: "hospitality",
                 //   imagesList: [HEARTHSTONEONEH],
               
                 //   desc: "",
                 // },
                 // {
         
                 //   title: "SUNLIT SPLENDOR",
                 //   slugs: "/interior_design-hospitality-sunlitsplendor",
                 //   category: "interior_design",
                 //   subCategory: "hospitality",
                 //   imagesList: [SUNLITSPLENDORONEH],
               
                 //   desc: "",
                 // },
         
         
         
         
         
         
         
         
         
                 // INTERIOR DESIGN Section , Sub Section :- Institutional
                 // {
         
                 //   title: "ICCR, DIGITAL EXHIBITION SPACE",
                 //   slugs: "/interior_design-institutional-iccrdigitalexhibitionspace",
                 //   category: ["interior_design"],
                 //   subCategory: ["institutional"],
                 //   imagesList: [ICCRDIGITALONE],
               
                 //   desc: "",
                 // },
                 {
         
                   title: "NSM school",
                   slugs: "/interior_design-institutional-nsmschool",
                   category: ["interior_design"],
                   subCategory: ["institutional"],
                   imagesList: [NSMSCHOOLONE,onep,twop,threep,fourp,fivep,sixp,sevenp,eightp,ninep,tenp,elevenp,twelvep,thirteenp,fourteenp,fifteenp,sixteenp],
                   location:"Mumbai",
                   area:"25,000 sqft",
                   status:'BUILT',
                   desc: "The NSM School revamp transforms the 75-year-old building into a contemporary learning environment that balances functional needs with vibrant, age-appropriate design. The administrative area adopts a semi-open layout with glass partitions, ensuring visibility and openness while maintaining essential privacy for staff. Corridors are enhanced with bold, color-coded ceiling grids that guide movement and add visual interest, while playful wall murals introduce thematic storytelling throughout the school. Each classroom is thoughtfully tailored to engage different age groups, with interactive decor, vibrant colors, and modular elements that create an immersive, lively atmosphere for learning. The overall design respects the school’s heritage while infusing it with a modern aesthetic that revitalizes the space and enhances the experience for both students and staff.				",
                 },
         
         
                   
         
         
         
                 // INTERIOR DESIGN Section , Sub Section :- Amenities
                 // {
         
                 //   title: "ICCR, DIGITAL EXHIBITION SPACE",
                 //   slugs: "/interior_design-amenities-iccrdigitalexhibitionspace",
                 //   category: ["interior_design"],
                 //   subCategory: ["institutional","amenities"],
                 //   imagesList: [ICCRDIGITALONEA],
               
                 //   desc: "",
                 // },
                 {
                   title: "Trupti Residence",
                   slugs: "/landscape-trupti-residence",
                   category: ["interior_design"],
                   subCategory: ["amenities"],
                   imagesList: [TRUPTII,oner,twor,threer,fourr,fiver,sixr,sevenr,eightr,niner,tenr,elevenr,],
                   location:"Mumbai",
                   area:"1,00,000 sqft",
                   status:'IN PROGRESS',
                   desc: "Trupti Residence offers a luxurious and thoughtfully designed living experience with amenity spaces like a sophisticated reception area featuring natural wood finishes, textured stone walls, and warm lighting, creating a welcoming ambiance. The residence also includes a peaceful meditation room for relaxation, a state-of-the-art fitness center for an active lifestyle, and a cozy café library that blends modern café vibes with a tranquil reading environment. Each space is designed to enhance well-being, comfort, and a sense of community, reflecting a harmonious blend of style and functionality.",
                 },
                 
         
         
         
         
         
         
         
                   //LANDSCAPE
                   {
                     title: "BANK OF INDIA ",
                     slugs: "/landscape-bankofindia",
                     category: ["landscape"],
                     subCategory: [""],
                     imagesList: [BANKOFINDIAONE,onel,twol,threel,fourl,fivel,sixl,sevenl,eightl],
                     location:"Mumbai",
                     area:"5500 sqft",
                     status:'BUILT',
                     desc: `The landscape project for Bank of India features a well-designed outdoor space, emphasizing greenery and lighting to create a serene and welcoming atmosphere. The use of vertical gardens, accent lighting, and carefully placed seating areas contributes to a relaxing environment. The design blends modern aesthetics with natural elements, enhancing the bank's message of "Relationship beyond banking." This outdoor space offers a peaceful retreat while maintaining a sense of sophistication and professionalism aligned with the bank's brand identity.				`,
                   },
         
                   // {
                   //   title: "JIJAMATA UDHYAN ",
                   //   slugs: "/landscape-jijamata-udhyan",
                   //   category: "landscape",
                   //   subCategory: "",
                   //   imagesList: [JIJAMATAUDHYANONE],
                   //   desc: "",
                   // },
         
                   {
                     title: "Tripti Terrace",
                     slugs: "/landscape-tripti-terrace",
                     category: ["landscape"],
                     subCategory: [""],
                     imagesList: [TRUPTIONE,onem,twom,threem,fourm,fivem,sixm,sevenm,eightm,ninem,tenm,elevenm],
                     location:"Mumbai",
                     area:"7500 sqft",
                     status:'IN PROGRESS',
                     desc: "This terrace and recreational space for the apartment complex has been thoughtfully designed to create a dynamic, inviting environment for residents. A mix of green landscaping and modern architectural elements brings a sense of balance and tranquility to the rooftop. The central structure is visually striking, featuring an artistic façade with illuminated, wave-like patterns that add a sense of movement and elegance to the space. Surrounding it, pathways lined with greenery and seating nooks encourage leisurely strolls and quiet relaxation. There are also open areas with a variety of seating options, ideal for small gatherings or social interactions among residents. Pergolas and sheltered zones enhance the usability of the terrace, making it accessible and enjoyable throughout the day. Thoughtful lighting accents the architectural features, creating a welcoming ambiance that resonates well into the evening.",
                   },
         
         
                   //PLANNING
                   // {
                   //   title: "BALBALA MARKET",
                   //   slugs: "/planning-balbalamarket",
                   //   category: "planning",
                   //   subCategory: "",
                   //   imagesList: [BALBALAMARKETONE],
                   //   desc: "",
                   // },
                   // {
                   //   title: "CHINCHPOKLI STATION",
                   //   slugs: "/planning-chinchpoklistation",
                   //   category: ["planning"],
                   //   subCategory: "",
                   //   imagesList: [CHINCHPOKLISTATIONONE],
                   //   desc: "",
                   // },
                   // {
                   //   title: "ISKCON ECO VILLAGE",
                   //   slugs: "/planning-iskcon-ecovillage",
                   //   category: "planning",
                   //   subCategory: "",
                   //   imagesList: [ISKCONECOVILLAGEONE],
                   //   desc: "",
                   // },
         
         
                   //FACADE
                   {
                     title: "HINDU GHYMKHANA",
                     slugs: "/facade-hindu-ghymkhana",
                     category: ["facade"],
                     subCategory: [""],
                     imagesList: [HINDUGHYMKHANAONE,onen,twon,threen,fourn,fiven,sixn,sevenn,eightn,ninen,tenn],
                     location:"Mumbai",
                     area:"-",
                     status:'BUILT',
                     desc: "This facade design for the PJ Hindu Gymkhana in Mumbai integrates traditional motifs with a modern approach, crafted in GFRC (Glass Fiber Reinforced Concrete) to bring durability and elegance to the city skyline. The intricate pattern across the facade is inspired by classic geometric designs, adding cultural depth while complementing the building's formal structure. The neutral color palette is chosen to harmonize with the urban landscape, while the textured surface provides visual interest and catches light subtly, creating a refined, timeless look. Flanked by two tall, symmetrical elements, the facade’s composition achieves both stateliness and harmony, making it a prominent architectural feature on Mumbai’s iconic Marine Drive.",
                   },
                   {
                     title: "Trupti Facade",
                     slugs: "/facade-trupti-facade",
                     category: ["facade"],
                     subCategory: [""],
                     imagesList: [TRUPTIONEF,oneq,twoq,threeq,fourq,fiveq,sixq,sevenq],
                     location:"Mumbai",
                     area:"-",
                     status:'IN PROGRESS',
                     desc: "facade design for this apartment complex emphasizes a modern and dynamic aesthetic through the use of layered architectural elements. The building exterior features distinct rectangular frames around each section of windows, adding depth and dimension. Vertical slats within these frames provide texture and visual interest, while also offering some privacy for the residents. The facade integrates a mix of materials, including exposed brick and sleek metal finishes, creating a balanced interplay between warmth and sophistication. Soft, integrated lighting accentuates the design at night, highlighting the architectural details and creating a welcoming ambiance for residents and visitors alike. The rooftop is adorned with lush greenery, adding a natural element to the overall urban design.",
                   },
                   {
                     title: "NASIK OFFICE",
                     slugs: "/facade-nasikoffice",
                     category: ["facade"],
                     subCategory: [""],
                     imagesList: [NASIKOFFICE,oneo,twoo,threeo,fouro],
                     location:"Nasik",
                     area:"-",
                     status:'BUILT',
                     desc: "The facade for this commercial building in Nasik reflects a modern, clean, and dynamic aesthetic. The design integrates smooth, curved white bands wrapping around the building, contrasting with vertical wooden louvers that add warmth and texture to the sleek structure. The interplay between these elements creates visual depth while maintaining a sophisticated and contemporary look. The minimalistic lighting accentuates the building’s architectural lines, enhancing its presence, especially in the evening. The commercial ground floor with large glass panels offers a transparent and inviting frontage, ensuring both visibility and style.",
                   },
                   // {
                   //   title: "TRUPTI",
                   //   slugs: "/facade-trupti",
                   //   category: "facade",
                   //   subCategory: "",
                   //   imagesList: [TRUPTIONEF],
                   //   desc: "",
                   // },
         
         
         
         
         
         
         
         
         
         
         
         
         
         //     {
         //         title: "Balbala Market",
         //         slugs: "/balbala_market",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [balaji1,balaji2,balaji3],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "Buddha Bhumi",
         //         slugs: "/buddha_bhumi",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [buddha1,buddha10,buddha11,buddha12,buddha13,buddha2,buddha3,buddha4,buddha5,buddha6,buddha7,buddha8,buddha9],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "Chinchpokli Station",
         //         slugs: "/chinchpokli station",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [chin1,chin2,chin3,chin4,chin5],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "Iskon Eco Village",
         //         slugs: "/Iskon_eco_village",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [iskon1,iskon2],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "dindoshi",
         //         slugs: "/dindoshi",
         //         category: "planning",
         //         subCategory: "villas",
         //         imagesList: [din1,din2,din3],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "mlcp",
         //         slugs: "/mlcp",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [mlcp1,mlcp2,mlcp3],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //       {
         //         title: "MCLP Mumbai",
         //         slugs: "/mclpmumbai",
         //         category: "architecture",
         //         subCategory: "villas",
         //         imagesList: [mlcpmum1,mlcpmum2,mlcpmum3],
             
         //         desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //       },
         //   {
         
         //     title: "Ashok Vatika",
         //     slugs: "/architecture-residential-ashokvatika",
         //     category: "architecture",
         //     subCategory: "villas",
         //     imagesList: [one, two, three, four, ashokvatikaone],
         
         //     desc: "A contemporary Villa in Ahmedabad. The intent behind this Villa was to avoid and solid mass and instead create a design with fragmented volumes connected with covered walkways and colonnades along the periphery, therefore having a clear distinction between the served and service spaces. The colonnades shade the serviced areas. This fragmentation also helps bring in the waterbodies and the landscape into the house, creating a microclimate the complements the climatic context. The villa has an overall modern design language with clean lines, segmented openings but the minimal ornamentation has ethnic elements.",
         //   },
         
         //   {
         //     title: "Hearthstone",
         //     slugs: "/architecture-residential-Hearthstone",
         //     category: "architecture",
         //     subCategory: "villas",
         //     imagesList: [Hearthstone, hone, htwo, hthree, hfour],
         
         //     desc: " Hearthstone is a homestay, with a strong focus on hospitality. This is the 1st flagship project of Getaway Stays, a homestay company started by a young couple with a background and education in hospitality. Built on the heart of their anscestoral home, hence the name: Hearthstone, the building endorses the quintessential home. It embodies the iconic house shape which is further reinforced through the the windows. As one approaches the home, the recognisable front elevation breeds familiarity and a sense of comfort, which is completed by the welcoming brick clad, inset, entry verandah. The pool facing elevation is layered and designed to evoke a sense of luxury. This home stay has 4 bedroom on the 4 corners and a living room with a open kitchen on the ground floor. The interior design continues the external theme of comfort by using a soft pastel colour pallette, natural materials and soft textures. Bespoke furniture pieces lends it premium ness and custom murals and paintings reflects the context. The bedrooms use abstract patterns, and let the natural light fill up the room and take prominence. The use of teak wood, soft pastels, custom handles and soft edges on the false ceiling, bestow the space a feeling of serenity, in a contemporary setting, making it the perfect retreat. The 1st floor is the piece de resistance of the home. An open plan with a centrally located bar and a gaming area, a cozy tv space, lounge and dinning in the 4 corners. A large balcony opens up to the pool. The elevation of the rear walls reflect the front, and make for a lovely green frame. The exposed ac ducts are painted golden to add a splash of luxury. The bespoke elements and attention to detail continues on the 1st floor. The 30 mural used for the bar backdrop along with the choice of wallpaper, brings in some of the green.",
         //   },
         
         //   {
         //     title: "TWIN VILLA ,DJIBOUTI",
         //     slugs: "/TWIN_VILLA_DJIBOUTI",
         //     category: "architecture",
         //     subCategory: "villas",
         //     imagesList: [twinone, twintwo, twinthree],
         
         //     desc: "The brief for this project was to design a luxury neo Arabesque style Villa. Internal space planning, circulation and the quality of spaces defined by their volume took precedence in this project.Djibouti has a French Islamic architectural context, with arabesque elements dominating the facade. This luxury Villa uses these arabesque elements as ornaments and fenestrations on the facade, without making it overtly decorated. It is our take on the neo Arabesque style. The interior spaces are also inspired from the neo arabesque style. The end product resorts to a more hybrid I eclectic design style. Intricacy takes precedence over simplicity. Luxury evokes from an appreciation for quality of materials, design patterns, craftsmanship and embelishment of spaces. Through our design we have consciously tried to balance complexity of the space such that it can be appreciated by today's distracted mind and does not appear to be an overindulgence of sorts. We intended for this place to appear luxurious and elegant and not decadent and hedonistic.",
         //   },
         //   {
         //     title: "MASHRABIA VILLA ,DJIBOUTI",
         //     slugs: "/architecture-residential-MASHRABIAVILLADJIBOUTI",
         //     category: "architecture",
         //     subCategory: "villas",
         //     imagesList: [MASHRABIAVILLADJIBOUTI, mone, mtwo, mthree, mfour],
         //     desc: "",
         //     desc: "The brief for this project was to design a luxury neo Arabesque style Villa. Internal space planning, circulation and the quality of spaces defined by their volume took precedence in this project.Djibouti has a French Islamic architectural context, with arabesque elements dominating the facade. This luxury Villa uses these arabesque elements as ornaments and fenestrations on the facade, without making it overtly decorated. It is our take on the neo Arabesque style. The interior spaces are also inspired from the neo arabesque style. The end product resorts to a more hybrid I eclectic design style. Intricacy takes precedence over simplicity. Luxury evokes from an appreciation for quality of materials, design patterns, craftsmanship and embelishment of spaces. Through our design we have consciously tried to balance complexity of the space such that it can be appreciated by today's distracted mind and does not appear to be an overindulgence of sorts. We intended for this place to appear luxurious and elegant and not decadent and hedonistic.",
         //   },
         
         //   {
         //     title: "BANK OF INDIA ",
         //     slugs: "/architecture-residential-TWINVILLASATARA",
         //     category: "architecture",
         //     subCategory: "landscape",
         //     imagesList: [b2, b3, b4, b5, b7, b8],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "JIJAMATA",
         //     slugs: "/architecture-residential-TWINVILLASATARA",
         //     category: "architecture",
         //     subCategory: "landscape",
         //     imagesList: [jija1, jija2, jija3],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "HINDHU GYMKHANA",
         //     slugs: "/architecture-residential-TWINVILLASATARA",
         //     category: "architecture",
         //     subCategory: "facade",
         //     imagesList: [hind2, hind4, hind6, hind8, hind10],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "Nasik OFFICE",
         //     slugs: "/architecture-residential-TWINVILLASATARA",
         //     category: "architecture",
         //     subCategory: "facade",
         //     imagesList: [nashik1, nashik2, nashik3, nashik4],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "Trupti",
         //     slugs: "/trupti",
         //     category: "architecture",
         //     subCategory: "facade",
         //     imagesList: [TWINVILLASATARA, sone, stwo, sthree, sfour],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "Basen Villa",
         //     slugs: "/basen_villa",
         //     category: "interior_design",
         //     subCategory: "villas",
         //     imagesList: [basin1,basin10,basin3,basin4,basin5,basin6,basin7,basin8,basin9,basin10],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "Heart Stone",
         //     slugs: "/heart_stone",
         //     category: "interior_design",
         //     subCategory: "villas",
         //     imagesList: [Hearthstone,hone,htwo,hthree]
         // ,    desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "SUNLIT",
         //     slugs: "/SUNLIT",
         //     category: "interior_design",
         //     subCategory: "villas",
         //     imagesList: [sunlit1,sunlit2,sunlit3,sunlit4,sunlit5,sunlir6,sunlit7],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "The Art Gallery",
         //     slugs: "/theartgallery",
         //     category: "interior_design",
         //     subCategory: "villas",
         //     imagesList: [art1,art2,art3,art4,art5],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: "Gune Residence",
         //     slugs: "/guneresidence",
         //     category: "interior_design",
         //     subCategory: "apartments",
         //     imagesList: [gune1,gune3,gune4,gune5,gune6,gune7,gune8],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         //   {
         //     title: " Jain Residency",
         //     slugs: "/jainresidency",
         //     category: "interior_design",
         //     subCategory: "apartments",
         //     imagesList: [jain1,jain2,jain3,jain4,jain5,jain6,jain7,jain8],
         //     desc: "Brief was to design identical houses for 2 brothers in the form of a row house with equal access to the staircase. The house is meant to be built on a budget. The unique central staircase rising up from the front Verandah the large front facing terrace are the defining characteristics of the project. The 1st floor room is may be rented out as a guest room. The overal design differentiates it from the context. Attention to details elevate the aesthetic apeal of the space.",
         //   },
         ];
         
         export default Portfolidata;
         